/* eslint-disable @typescript-eslint/return-await */
import Axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosRequestHeaders,
  type AxiosResponse,
} from 'axios'

import { type ImageObject, getPhotoUploadData } from '@c/image-uploader'
import { type ScannerImage } from '@c/scandit/scanner'

import { type ImageFileType } from './constants'
import ImageNetworkError from './image-network-error'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NetworkErrorResposne<T = UhaulErrorResponse, C = any> = AxiosError<T, C>
export type NetworkImageErrorResposne = AxiosError<UhaulErrorResponse, ImagePayload>

const INPUT_TOKEN_NAME = '__RequestVerificationToken'

export function getTokenValue(): string | null {
  const elements = document.getElementsByName(INPUT_TOKEN_NAME)

  if (elements.length) {
    const input = elements[0] as HTMLInputElement

    return input.value
  }

  return null
}

export async function request(config: AxiosRequestConfig): Promise<AxiosResponse> {
  return Axios.request(config)
}

export function getGetRequest() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async <T = any, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<AxiosResponse<T, D>> =>
    request({
      ...config,
      method: 'get',
      url,
    })
}

export function getPostRequest() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async <T = any, D = any>(
    url: string,
    data: unknown = {},
    config: AxiosRequestConfig<D> = {},
  ): Promise<AxiosResponse<T, D>> => {
    const { headers, ...other } = config
    const token = getTokenValue()
    return request({
      ...other,
      headers: {
        ...headers,
        __RequestVerificationToken: token,
      } as unknown as AxiosRequestHeaders,
      method: 'post',
      url,
      data,
    })
  }
}

export async function getPayloadImageData(
  images: ImageObject[],
  fileTypes?: ImageFileType | (ImageFileType | undefined)[],
): Promise<ImagePayload[]> {
  function getFileType(index: number): ImageFileType | undefined {
    if (Array.isArray(fileTypes)) {
      return fileTypes[index]
    }

    return fileTypes
  }

  const imageData = await Promise.allSettled(
    images.map(async (img, index) => {
      return getPhotoUploadData(img, getFileType(index))
    }),
  )
  const hasImageErrors = imageData.some((p) => p.status === 'rejected')
  const imageDataErrors = hasImageErrors
    ? imageData.reduce((cur: string[], next: PromiseSettledResult<unknown>, index: number) => {
        if (next.status === 'rejected') return [...cur, images[index].name]

        return cur
      }, [])
    : undefined

  return new Promise((resolve, reject) => {
    if (hasImageErrors) reject(imageDataErrors)
    else
      resolve(
        imageData.map((i) => {
          return (i as PromiseFulfilledResult<ImagePayload>).value
        }),
      )
  })
}

export async function uploadImages(
  images: ImageObject[],
  fileTypes?: ImageFileType | ImageFileType[],
): Promise<string[]> {
  const request = getPostRequest()
  let imageData: ImagePayload[]
  let imageDataErrors: string[]
  let hasImageErrors = false

  try {
    imageData = await getPayloadImageData(images, fileTypes)
  } catch (error) {
    hasImageErrors = true
    imageDataErrors = error as string[]
  }

  const uploadResponses = !hasImageErrors
    ? await Promise.allSettled(imageData!.map(async (data) => request('/Photo/UploadImage', data)))
    : undefined
  const hasUploadErrors = uploadResponses?.some((r) => r.status === 'rejected')
  const uploadErrors = hasUploadErrors
    ? uploadResponses?.reduce(
        (cur: string[], next: PromiseSettledResult<unknown>, index: number) => {
          if (next.status === 'rejected') return [...cur, images[index].name]

          return cur
        },
        [] as string[],
      )
    : undefined

  return new Promise((resolve, reject) => {
    if (hasImageErrors) reject(imageDataErrors)
    else if (hasUploadErrors) reject(uploadErrors)
    else {
      const values = uploadResponses?.map(
        (r) => (r as PromiseFulfilledResult<AxiosResponse>).value.data.id,
      )

      resolve(values as string[])
    }
  })
}

export async function uploadSingleImage(
  image: ImageObject | ScannerImage,
  fileType: ImageFileType,
  payload?: Partial<ImageUploadRequest>,
): Promise<ImageUploadResponse> {
  const request = getPostRequest()
  const imageData = await getPhotoUploadData(image, fileType)

  const requestData: ImageUploadRequest = {
    isSecure: true,
    shouldSendToMLApi: false,
    ...imageData,
    ...payload,
  }

  const serverResponse = await request<{
    status: number
    response: ImageUploadResponse | UhaulErrorResponse
  }>('/Photo/Upload', requestData)

  if (serverResponse.data.status === 200) {
    return serverResponse.data.response as ImageUploadResponse
  }

  throw new ImageNetworkError(
    serverResponse.data.status,
    serverResponse.data.response as UhaulErrorResponse,
    requestData,
  )
}

export function getLogToServerRequest() {
  return async (eventName: string, errorMessage: string = '', options: AxiosRequestConfig = {}) => {
    const { headers = {}, ...config } = options ?? {}
    const token = getTokenValue()
    const queryString = new URLSearchParams({ eventName })

    if (errorMessage) queryString.set('errorMessage', errorMessage)

    try {
      await request({
        ...config,
        headers: {
          ...headers,
          __RequestVerificationToken: token,
        } as unknown as AxiosRequestHeaders,
        method: 'post',
        url: `/LogInfo?${queryString.toString()}`,
        data: {},
      })
    } catch (error) {
      if (ENV_NAME === 'Development') {
        console.error(
          `Failed to send log to server: Event: ${eventName} Message: ${errorMessage}`,
          error,
        )
      }
    }
  }
}

export const AxiosInstance = Axios
