import { Typography, type TypographyProps } from '@mui/material'

import { STATES_AND_PROVINCES, type STATES_AND_PROVINCES_TYPE } from './constants'

export function getPhoneNumberFromString(phoneNumber: string): string {
  return ('' + phoneNumber).replace(/\D/g, '')
}

export function getDisplayPhoneNumber(phoneNumber: string): string {
  const cleaned = getPhoneNumberFromString(phoneNumber)
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return ''
}

export function formatCurrency(currency: number): string {
  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  }).format(currency)
}

export function noWrapUhaul(content: string): React.ReactNode {
  return content.split(/(u-haul)/gi).map((part, index) => {
    if (part.toLowerCase() === 'u-haul') {
      return (
        <span key={index} style={{ whiteSpace: 'nowrap' }}>
          {part}
        </span>
      )
    }

    return part
  })
}

export interface StateAndProvincesOption {
  title: string
  value: string
  type: STATES_AND_PROVINCES_TYPE | ''
}
export function getStatesAndProvinces(hasEmpty?: boolean): StateAndProvincesOption[] {
  const options = STATES_AND_PROVINCES.map((item) => ({
    title: item.name,
    value: item.abbreviation,
    type: item.type,
  }))

  return hasEmpty ? [{ title: '', value: '', type: '' }, ...options] : options
}

export function getStateOrProvinceAbbreviation(longName: string): string {
  return STATES_AND_PROVINCES.find((o) => o.name === longName)?.abbreviation ?? ''
}

export function isEmptyString(check: unknown): boolean {
  return typeof check === 'string' ? !check.trim() : isNil(check)
}

export function isNil(check: unknown): boolean {
  return check == null
}

export function decodeHtmlEntity(str: string): string {
  return str.replace(/&#(\d+);/g, function (match: string, dec: number) {
    return String.fromCharCode(dec)
  })
}

const idCounter: Record<string, number> = {}
export function uniqueId(prefix = '$id$'): string {
  if (!idCounter[prefix]) {
    idCounter[prefix] = 0
  }

  const id = ++idCounter[prefix]
  if (prefix === '$id$') {
    return `${id}`
  }

  return `${prefix}${id}`
}

export function typographyWrapper(
  messages: React.JSX.Element | string | string[],
  options: TypographyProps = {},
): React.ReactNode {
  if (Array.isArray(messages)) {
    return messages.map((str, index) => (
      <Typography key={index} {...options}>
        {str}
      </Typography>
    ))
  }

  if (typeof messages === 'string') {
    return <Typography {...options}>{messages}</Typography>
  }

  return messages
}
export function getRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min)
}
export function shuffle<T = unknown>(array: T[]): T[] {
  const original = [...array]
  const copy = []
  let n = original.length
  let i

  // While there remain elements to shuffle…
  while (n) {
    // Pick a remaining element…
    i = Math.floor(Math.random() * n--)

    // And move it to the new array.
    copy.push(original.splice(i, 1)[0])
  }

  return copy
}
export function getQueryParam(param: string): string | null {
  const params = new URLSearchParams(window.location.search)

  return params.get(param)
}

export function isFrench(): boolean {
  const language = getQueryParam('language')
  if (!language) return false

  const hasFrenchParams = language.toLowerCase() === 'fr-ca'

  return window.location.href.includes('fr.') || hasFrenchParams
}

export function isSpanish(): boolean {
  const language = getQueryParam('language')
  if (!language) return false

  const hasSpanishParams = language.toLowerCase() === 'es-mx'

  return window.location.href.includes('es.') || hasSpanishParams
}

export function arrayFill<T = unknown>(
  length: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fill: T extends any ? T | ((valut: unknown, index: number) => T) : never,
): T[] {
  return Array.from({ length }, (value, index) => {
    return typeof fill === 'function' ? fill(value, index) : fill
  })
}

export function hasIllegalCharacterCheck(value: string): boolean {
  // eslint-disable-next-line no-control-regex
  if (value) return !/^[\x00-\x7F]+$/.test(value)

  return false
}

export function getUhaulLink(path: string): string {
  return `${getProperUhaulDomain()}${path}`
}
export function getProperUhaulDomain(): string {
  return ENV_NAME === 'Development' ? 'https://uhauld.com' : 'https://www.uhaul.com'
}

export function getHashCode(str: string): number {
  let hash = 0
  let i
  let chr

  if (str.length === 0) return hash

  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }

  return hash
}
