import { createContext, useContext } from 'react'

import { isAndroidApp, isIOSApp, isInHybridApp } from '@lib/hybrid-checker'

interface ProviderValue {
  isHybidLibEnabled: (type: HybridToggleType) => boolean
  getCameraSetting: (type: HybridToggleType) => HybridCameraSetting
}

const HybridContext = createContext<ProviderValue | undefined>(undefined)

interface HybridLibProviderProps extends HybridLibConfig {
  children: React.ReactNode
}
function HybridProvider({
  children,
  dlBackCameraSetting,
  dlBackPlatform,
  dlFrontCameraSetting,
  dlFrontPlatform,
  enableBackHybridLib,
  enableFrontHybridLib,
  enableHeadshotHybridLib,
  enableImageHybridLib,
  enableScannerHybridLib,
  headshotCameraSetting,
  headshotPlatform,
  imagePlatform,
  scannerPlatform,
}: HybridLibProviderProps): React.JSX.Element {
  function isHybidLibEnabled(type: HybridToggleType): boolean {
    const isInApp = isInHybridApp()
    const isIOS = isIOSApp()
    const isAndroid = isAndroidApp()

    let platform: HybridPlatformToggleValue = 'Off'
    let enabled: boolean = false
    switch (type) {
      case 'headshot':
        enabled = enableHeadshotHybridLib
        platform = headshotPlatform
        break
      case 'front':
        enabled = enableFrontHybridLib
        platform = dlFrontPlatform
        break
      case 'back':
        enabled = enableBackHybridLib
        platform = dlBackPlatform
        break
      case 'image':
        enabled = enableImageHybridLib
        platform = imagePlatform
        break
      case 'scanner':
        enabled = enableScannerHybridLib
        platform = scannerPlatform
    }

    return (
      isInApp &&
      enabled &&
      (platform === 'Both' ||
        (isIOS && platform === 'iOS') ||
        (isAndroid && platform === 'Android'))
    )
  }
  function getCameraSetting(type: HybridToggleType): HybridCameraSetting {
    switch (type) {
      case 'headshot':
        return headshotCameraSetting
      case 'front':
        return dlFrontCameraSetting
      case 'back':
        return dlBackCameraSetting
      default:
        return 'default'
    }
  }

  return (
    <HybridContext.Provider
      value={{
        isHybidLibEnabled,
        getCameraSetting,
      }}
    >
      {children}
    </HybridContext.Provider>
  )
}

function useHybridToggles(): ProviderValue {
  const context = useContext(HybridContext)

  if (context === undefined) {
    throw new Error('useHybridToggles must be used within a HybridProvider')
  }

  return context
}

export { HybridProvider, useHybridToggles }
