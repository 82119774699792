export default class ImageNetworkError extends Error {
  request: ImageUploadRequest
  response: UhaulErrorResponse
  status: number

  constructor(status: number, response: UhaulErrorResponse, request: ImageUploadRequest) {
    super('Image upload error')

    this.response = response
    this.status = status
    this.request = request
  }
}
